export default {
  fr: {
    name: 'Français',
    load: () => import('./fr.json'),
  },
  en: {
    name: 'English',
    load: () => import('./en.json'),
  },
};
