<script>
import TopNavigationBar from '@/components/top-navigation-bar/TopNavigationBar.vue';

export default {
  name: 'App',
  components: {
    TopNavigationBar,
  },
};
</script>

<template>
<!--  <top-navigation-bar></top-navigation-bar>-->
  <router-view />
</template>
