<script>
import 'intersection-observer';

export default {
  name: 'Home',
  components: {},
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {

  },
};
</script>

<template>
  <section class="section">
    <div class="cdreamsvideograph-container-fluid container-xl">
      <h1>Pages</h1>

      <ul>
        <li><localized-link to="/maintenance">maintenance</localized-link></li>
      </ul>
    </div>
  </section>
</template>
