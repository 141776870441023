<script>
export default {
  name: 'TopNavigationBar',
};
</script>

<template>
  <div class="top-navigation-bar"
       :class="{ 'top-navigation-bar--scroll': this.$store.state.currentIndexHeaderSentinel === 0 }">
    <div class="top-navigation-bar__container container-xl cdreamsvideograph-container-fluid">
      <div class="logo">
        <localized-link to="/">
          <svg class="logo__desktop" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.77 131.67">
            <g>
              <path d="M131.49,41.83c-5.82,0-8.64-4.46-8.64-10.37S125.67,21,131.49,21s8.73,4.54,8.73,10.45S137.4,41.83,131.49,41.83Zm-41.1,21h13.27V0H90.39ZM65.2,75C74.11,75,82,70.29,85.11,61.56L74.38,56.65c-1.63,3.64-4,5.55-8,5.55C61,62.2,57.93,58.74,57.93,52c0-6.55,2.91-10.46,8.36-10.46,3.73,0,6.28,1.82,7.37,6.27L85,43.29C82,31.83,73.66,28.83,66.29,28.83c-11.82,0-21.91,7.82-21.91,23.55C44.38,67.93,54.38,75,65.2,75ZM13.28,62.83c1-4.18,3.63-6.45,7.91-6.45,4.64,0,7.18,2.64,7.18,6.45Zm17,12.28a9.55,9.55,0,0,1-7.91,3.64c-4.46,0-7.92-1.91-9.19-6.91H40.83c1.55-20.28-8.55-27.1-19.37-27.1C9.91,44.74,0,52.92,0,68.11,0,83.48,10.09,90.93,21.28,90.93a22.49,22.49,0,0,0,18.91-9.82ZM131.49,54.47c14.18,0,22.28-11,22.28-23s-8.1-23.19-22.28-23.19S109.3,19.37,109.3,31.46,117.39,54.47,131.49,54.47Z" fill="#fa2e1f"/>
              <g style="isolation: isolate">
                <g style="isolation: isolate">
                  <path d="M9.39,112.83a1.64,1.64,0,0,0,.23,1.07,1.49,1.49,0,0,0,1,.29v.48H6.71v-.48a1.71,1.71,0,0,0,1-.29A1.56,1.56,0,0,0,8,112.83v-3.54c0-.78-.16-1-.88-1H4.22v4.5a1.46,1.46,0,0,0,.28,1.06,1.94,1.94,0,0,0,1.17.3v.48H1.3v-.48a2.3,2.3,0,0,0,1.26-.29c.18-.14.26-.51.26-1.07v-4.5H1.3v-.5l1.52-.67c.11-2.51,1.53-4,3.89-4,1.52,0,2.49.56,2.49,1.4a.85.85,0,0,1-.89,1,.81.81,0,0,1-.82-.48,3.54,3.54,0,0,1-.18-.57,2.11,2.11,0,0,0-.24-.61,1.69,1.69,0,0,0-.49-.06c-1.49,0-2.42,1-2.42,2.76a3.8,3.8,0,0,0,0,.53H8.56L9,107h.44Z" fill="#fa2e1f"/>
                  <path d="M18.21,113c0,.91.18,1.12,1.07,1.2v.48H15.79v-.48c.85-.08,1-.26,1-1.2v-3.2c0-1.07-.46-1.62-1.41-1.62a2.45,2.45,0,0,0-1.84.91V113c0,.91.19,1.12,1,1.2v.48H11.07v-.48c.9-.08,1.07-.29,1.07-1.2v-3.92c0-.91-.19-1.12-1.07-1.12v-.42l2-.54h.45v1.44a2.61,2.61,0,0,1,2.4-1.54c1.46,0,2.26.9,2.26,2.63Z" fill="#fa2e1f"/>
                  <path d="M27.12,113c-.17,1.21-.53,1.79-1.53,1.79a1.36,1.36,0,0,1-1.45-1.36A2.43,2.43,0,0,1,22,114.89a1.68,1.68,0,0,1-1.85-1.71c0-1.09.7-1.66,2.24-2.16l1.74-.54v-.66c0-1.28-.61-1.95-1.68-1.95a3.2,3.2,0,0,0-1.57.37,1.43,1.43,0,0,0,.44.72,1.16,1.16,0,0,1,.36.72.94.94,0,0,1-1,.89,1,1,0,0,1-1-1.12,2.17,2.17,0,0,1,1.06-1.79,3.83,3.83,0,0,1,2.3-.77c1.52,0,2.48.93,2.48,2.83V113c0,.5.15.76.45.76s.53-.29.67-.85Zm-3-.32v-1.56l-1.12.4c-1,.34-1.37.63-1.37,1.27a.89.89,0,0,0,1,.93A1.82,1.82,0,0,0,24.11,112.72Z" fill="#fa2e1f"/>
                  <path d="M34.34,113c0,.91.17,1.12,1.07,1.2v.48H31.92v-.48c.85-.08,1-.26,1-1.2v-3.2c0-1.07-.47-1.62-1.41-1.62a2.43,2.43,0,0,0-1.84.91V113c0,.91.19,1.12,1,1.2v.48H27.2v-.48c.9-.08,1.07-.29,1.07-1.2v-3.92c0-.91-.19-1.12-1.07-1.12v-.42l2-.54h.45v1.44a2.61,2.61,0,0,1,2.4-1.54c1.46,0,2.26.9,2.26,2.63Z" fill="#fa2e1f"/>
                  <path d="M36,111a4.33,4.33,0,0,1,1-3,3.46,3.46,0,0,1,2.67-1.14c1.53,0,2.58.83,2.58,2,0,.75-.41,1.23-1.05,1.23a.9.9,0,0,1-1-.92,1.37,1.37,0,0,1,.29-.76,2.39,2.39,0,0,0,.35-.56,1.89,1.89,0,0,0-1.1-.29c-1.49,0-2.43,1.11-2.43,2.84s.93,2.91,2.38,2.91a2.13,2.13,0,0,0,2-1.26l.51.14c-.33,1.81-1.21,2.67-2.86,2.67C37.3,114.89,36,113.39,36,111Z" fill="#fa2e1f"/>
                  <path d="M44.45,110.06a2.2,2.2,0,0,0,0,.42c0,1.73.93,2.86,2.39,2.86a2.15,2.15,0,0,0,2-1.26l.51.14c-.34,1.81-1.22,2.67-2.87,2.67a3.19,3.19,0,0,1-2.51-1,4,4,0,0,1-.93-2.79,4.6,4.6,0,0,1,.93-3,3.06,3.06,0,0,1,2.51-1.14c1.88,0,2.93,1.14,2.92,3.17Zm.08-.69h3.33c0-1.13-.5-1.82-1.52-1.82S44.77,108.2,44.53,109.37Z" fill="#fa2e1f"/>
                  <path d="M55.83,108a1,1,0,0,1-1,1,.76.76,0,0,1-.64-.4,1.86,1.86,0,0,0-.48-.46,5.67,5.67,0,0,0-1.19,2.32V113c0,.91.26,1.12,1.36,1.2v.48H50.06v-.48c.9-.08,1.08-.29,1.08-1.2v-3.92c0-.91-.2-1.12-1.08-1.12v-.42l2-.54h.44v2.35a5.86,5.86,0,0,1,.87-1.71,1.57,1.57,0,0,1,1.26-.74A1.07,1.07,0,0,1,55.83,108Z" fill="#fa2e1f"/>
                </g>
                <g style="isolation: isolate">
                  <path d="M3.9,125.83a1.64,1.64,0,0,0,.23,1.07,1.49,1.49,0,0,0,1,.29v.48H1.3v-.48a1.49,1.49,0,0,0,1-.29,1.64,1.64,0,0,0,.23-1.07v-7.44c0-.85-.2-1-1.08-1v-.42l2.1-.62H3.9Z" fill="#fa2e1f"/>
                  <path d="M6.88,123.06a2.2,2.2,0,0,0,0,.42c0,1.73.93,2.86,2.39,2.86a2.15,2.15,0,0,0,2.05-1.26l.51.14c-.34,1.81-1.22,2.67-2.87,2.67a3.2,3.2,0,0,1-2.51-1,4,4,0,0,1-.93-2.79,4.6,4.6,0,0,1,.93-3,3.06,3.06,0,0,1,2.51-1.14c1.88,0,2.93,1.14,2.92,3.17Zm.08-.69h3.33c0-1.13-.5-1.82-1.52-1.82S7.2,121.2,7,122.37Z" fill="#fa2e1f"/>
                  <path d="M15.45,124a4.33,4.33,0,0,1,1-3,3.46,3.46,0,0,1,2.67-1.14c1.52,0,2.58.83,2.58,2a1.08,1.08,0,0,1-1.06,1.23.89.89,0,0,1-1-.92,1.37,1.37,0,0,1,.29-.76,2.8,2.8,0,0,0,.35-.56,1.89,1.89,0,0,0-1.1-.29c-1.49,0-2.43,1.11-2.43,2.84s.92,2.91,2.38,2.91a2.15,2.15,0,0,0,2.05-1.26l.51.14c-.33,1.81-1.22,2.67-2.86,2.67C16.78,127.89,15.45,126.39,15.45,124Z" fill="#fa2e1f"/>
                  <path d="M29.46,126c0,.91.17,1.12,1.07,1.2v.48H27v-.48c.85-.08,1-.26,1-1.2v-3.2c0-1.07-.47-1.62-1.41-1.62a2.43,2.43,0,0,0-1.84.91V126c0,.91.19,1.12,1,1.2v.48H22.32v-.48c.89-.08,1.07-.29,1.07-1.2v-7.6c0-.85-.19-1-1.07-1v-.42l2.1-.62h.38v5.12a2.61,2.61,0,0,1,2.4-1.54c1.46,0,2.26.9,2.26,2.63Z" fill="#fa2e1f"/>
                  <path d="M38.37,126c-.18,1.21-.53,1.79-1.54,1.79a1.36,1.36,0,0,1-1.44-1.36,2.43,2.43,0,0,1-2.16,1.42,1.69,1.69,0,0,1-1.86-1.71c0-1.09.71-1.66,2.25-2.16l1.74-.54v-.66c0-1.28-.61-1.95-1.68-1.95a3.17,3.17,0,0,0-1.57.37,1.4,1.4,0,0,0,.43.72,1.18,1.18,0,0,1,.37.72.94.94,0,0,1-1,.89,1,1,0,0,1-1-1.12A2.19,2.19,0,0,1,32,120.66a3.88,3.88,0,0,1,2.31-.77c1.52,0,2.48.93,2.48,2.83V126c0,.5.14.76.45.76s.53-.29.67-.85Zm-3-.32v-1.56l-1.12.4c-1,.34-1.38.63-1.38,1.27a.9.9,0,0,0,1,.93A1.82,1.82,0,0,0,35.36,125.72Z" fill="#fa2e1f"/>
                  <path d="M45.59,126c0,.91.17,1.12,1.07,1.2v.48H43.17v-.48c.85-.08,1-.26,1-1.2v-3.2c0-1.07-.47-1.62-1.41-1.62a2.43,2.43,0,0,0-1.84.91V126c0,.91.19,1.12,1,1.2v.48H38.45v-.48c.89-.08,1.07-.29,1.07-1.2v-3.92c0-.91-.19-1.12-1.07-1.12v-.42l2-.54h.45v1.44a2.61,2.61,0,0,1,2.4-1.54c1.46,0,2.26.9,2.26,2.63Z" fill="#fa2e1f"/>
                  <path d="M54.63,120.08v1.25H53.27a2.48,2.48,0,0,1,.25,1.14c0,1.55-1.18,2.57-3,2.57a3.74,3.74,0,0,1-1.47-.27c-.36.27-.53.53-.53.77,0,.5.33.74,1,.74h2.81c1.52,0,2.28.65,2.28,2,0,1.77-1.53,2.88-4.07,2.88a5.25,5.25,0,0,1-2.58-.56,1.71,1.71,0,0,1-1-1.49c0-.63.42-1.12,1.24-1.49a1.3,1.3,0,0,1-1-1.33c0-.7.48-1.28,1.42-1.71a2.34,2.34,0,0,1-1.09-2.08c0-1.55,1.19-2.58,3-2.58a4,4,0,0,1,1.25.19Zm-5.4,7.65h-.45a1.61,1.61,0,0,0-.48,1.15c0,1,.82,1.59,2.36,1.59s2.67-.69,2.67-1.67c0-.7-.51-1.06-1.52-1.06ZM52,122.6c0-1.16-.5-2.07-1.57-2.07-.83,0-1.41.61-1.41,1.81s.5,2.06,1.57,2.06C51.44,124.4,52,123.8,52,122.6Z" fill="#fa2e1f"/>
                  <path d="M56.56,123.06a2.2,2.2,0,0,0,0,.42c0,1.73.93,2.86,2.39,2.86a2.15,2.15,0,0,0,2-1.26l.51.14c-.34,1.81-1.22,2.67-2.87,2.67a3.2,3.2,0,0,1-2.51-1,4,4,0,0,1-.93-2.79,4.6,4.6,0,0,1,.93-3,3.06,3.06,0,0,1,2.51-1.14c1.88,0,2.93,1.14,2.92,3.17Zm.08-.69H60c0-1.13-.5-1.82-1.52-1.82S56.88,121.2,56.64,122.37Z" fill="#fa2e1f"/>
                  <path d="M73.52,125.83a1.73,1.73,0,0,0,.21,1c.13.18.42.27.88.32v.48H71.09v-.48c.43,0,.7-.14.83-.32a1.85,1.85,0,0,0,.19-1v-3.12c0-1-.46-1.54-1.39-1.54a2.28,2.28,0,0,0-1.66.79c0,.24,0,.41,0,.56v3.31c0,1,.16,1.28,1,1.36v.48H66.66v-.48c.43,0,.7-.14.83-.32a1.85,1.85,0,0,0,.19-1v-3.17c0-1-.43-1.49-1.3-1.49a2.35,2.35,0,0,0-1.72.91V126c0,.91.19,1.12,1,1.2v.48H62.17v-.48c.9-.08,1.08-.29,1.08-1.2v-3.92c0-.91-.2-1.12-1.08-1.12v-.42l2-.54h.45v1.44A2.62,2.62,0,0,1,67,119.89a1.87,1.87,0,0,1,2,1.52,2.66,2.66,0,0,1,2.32-1.52c1.49,0,2.24.93,2.24,2.77Z" fill="#fa2e1f"/>
                  <path d="M76.54,123.06c0,.08,0,.22,0,.42,0,1.73.93,2.86,2.38,2.86a2.14,2.14,0,0,0,2-1.26l.51.14c-.33,1.81-1.21,2.67-2.86,2.67a3.21,3.21,0,0,1-2.52-1,4,4,0,0,1-.92-2.79,4.6,4.6,0,0,1,.92-3,3.08,3.08,0,0,1,2.52-1.14c1.87,0,2.93,1.14,2.91,3.17Zm.08-.69H80c0-1.13-.49-1.82-1.52-1.82S76.86,121.2,76.62,122.37Z" fill="#fa2e1f"/>
                  <path d="M89.3,126c0,.91.17,1.12,1.07,1.2v.48H86.88v-.48c.85-.08,1-.26,1-1.2v-3.2c0-1.07-.47-1.62-1.41-1.62a2.43,2.43,0,0,0-1.84.91V126c0,.91.19,1.12,1,1.2v.48H82.16v-.48c.89-.08,1.07-.29,1.07-1.2v-3.92c0-.91-.19-1.12-1.07-1.12v-.42l2-.54h.45v1.44a2.61,2.61,0,0,1,2.4-1.54c1.46,0,2.26.9,2.26,2.63Z" fill="#fa2e1f"/>
                  <path d="M96,126.31c-.65,1.09-1.31,1.52-2.16,1.52a1.89,1.89,0,0,1-1.94-2.16v-4.34H90.53v-.66a2.18,2.18,0,0,0,2-1.93h.7v1.34h2.2v1.25h-2.2v4c0,.86.34,1.29,1,1.29a2,2,0,0,0,1.36-.59Z" fill="#fa2e1f"/>
                </g>
              </g>
            </g>
          </svg>
          <svg class="logo__mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 44.94">
            <path d="M65,20.67c-2.88,0-4.27-2.2-4.27-5.12s1.39-5.17,4.27-5.17,4.31,2.25,4.31,5.17S67.91,20.67,65,20.67ZM44.67,31.06h6.57V0H44.67Zm-12.45,6a10,10,0,0,0,9.85-6.65L36.77,28a4,4,0,0,1-4,2.74c-2.65,0-4.18-1.71-4.18-5s1.44-5.17,4.13-5.17c1.85,0,3.1.9,3.64,3.1L42,21.39c-1.48-5.66-5.62-7.15-9.26-7.15-5.84,0-10.83,3.87-10.83,11.65s4.95,11.19,10.3,11.19Zm-25.66-6a3.73,3.73,0,0,1,3.91-3.19c2.29,0,3.55,1.3,3.55,3.19ZM15,37.12a4.67,4.67,0,0,1-3.91,1.8,4.23,4.23,0,0,1-4.54-3.41H20.18c.76-10-4.22-13.39-9.57-13.39C4.9,22.12,0,26.16,0,33.67S5,45,10.52,45a11.11,11.11,0,0,0,9.34-4.86Zm50-10.2c7,0,11-5.43,11-11.37S72,4.09,65,4.09,54,9.58,54,15.55s4,11.37,11,11.37Z" fill="#fa2e1f"/>
          </svg>
          <div class="visually-hidden">{{ $t('logo') }}</div>
        </localized-link>
      </div>

<!--      <language-switcher v-slot="{ links }" tag="ul" active-class="router-link-exact-active">-->
<!--        <li :class="link.activeClass" v-for="link in links" :key="link.langIndex">-->
<!--          <a :href="link.url"><span>{{ link.langName }}</span></a>-->
<!--        </li>-->
<!--      </language-switcher>-->
    </div>
  </div>
</template>
