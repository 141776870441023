import { createApp } from 'vue';
import { createStore } from 'vuex';
import YoutubeIframe from '@techassi/vue-youtube-iframe';
import { i18n } from 'vue-lang-router';
import App from './App.vue';
import './assets/scss/main.scss';
import router from './router';

// Create a new store instance.
const store = createStore({
  state() {
    return {
      currentIndexHeaderSentinel: null,
    };
  },
  mutations: {
    updateSentinelCurrentIndex(state, newValue) {
      state.currentIndexHeaderSentinel = newValue;
    },
  },
});

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(YoutubeIframe)
  .mount('#app');
