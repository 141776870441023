import { createWebHistory } from 'vue-router';
import { createLangRouter } from 'vue-lang-router';
import Home from '../views/Home.vue';
import translations from '../lang/translations';
import localizedURLs from '../lang/localized-urls';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "maintenance" */ '../views/Maintenance.vue'),
  },
];

const langRouterOptions = {
  defaultLanguage: 'en',
  translations,
  localizedURLs,
};
const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
};
const router = createLangRouter(langRouterOptions, routerOptions);

export default router;
